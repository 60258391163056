var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aod-detail-item"
  }, [_c('van-cell', {
    attrs: {
      "title": _vm.aod.after_order.num,
      "value": "\uFFE5".concat(_vm.aod.amount),
      "value-class": "amount"
    }
  }), _c('van-cell', {
    attrs: {
      "value-class": "client-name",
      "title": "客户名称",
      "value": _vm.aod.client.name
    }
  }), _c('van-cell', {
    attrs: {
      "title": "查看收款码",
      "is-link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('show-qrcode', _vm.aod);
      }
    }
  }), _c('van-collapse', {
    attrs: {
      "border": false
    },
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, [_c('van-collapse-item', {
    attrs: {
      "name": "1",
      "title": "查看明细"
    }
  }, [_vm.aod.after_goods_list.length > 0 ? _vm._l(_vm.aod.after_goods_list, function (afterGoods, index) {
    return _c('aod-item-goods', {
      key: index,
      staticClass: "aod-goods",
      attrs: {
        "goods": afterGoods
      }
    });
  }) : _vm._l(_vm.aod.after_goods_diff_list, function (afterGoods, index) {
    return _c('aod-item-goods-diff', {
      key: index,
      staticClass: "aod-goods",
      attrs: {
        "goods": afterGoods
      }
    });
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }