var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aod-detail-goods"
  }, [_c('van-image', {
    staticClass: "image",
    attrs: {
      "src": _vm.goods.thumb_image
    }
  }), _c('div', {
    staticClass: "info"
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.goods.name))]), _vm.goods.good_diff_quantity > 0 ? _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("完好的差："), _c('van-tag', {
    attrs: {
      "type": "success"
    }
  }, [_vm._v(_vm._s("".concat(_vm.goods.good_diff_quantity).concat(_vm.goods.least_unit)))])], 1) : _vm._e(), _vm.goods.bad_diff_quantity > 0 ? _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("损坏的差："), _c('van-tag', {
    attrs: {
      "type": "danger"
    }
  }, [_vm._v(_vm._s("".concat(_vm.goods.bad_diff_quantity).concat(_vm.goods.least_unit)))])], 1) : _vm._e(), _vm.goods.accept_remark ? _c('div', {
    staticClass: "sub-title"
  }, [_vm._v("验收备注：" + _vm._s(_vm.goods.accept_remark))]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }