<template>
  <div class="aod-detail-item">
    <van-cell :title="aod.after_order.num" :value="`￥${aod.amount}`" value-class="amount" />
    <van-cell value-class="client-name" title="客户名称" :value="aod.client.name" />
    <van-cell title="查看收款码" is-link @click="$emit('show-qrcode', aod)" />
    <van-collapse v-model="activeNames" :border="false">
      <van-collapse-item name="1" title="查看明细">
        <template v-if="aod.after_goods_list.length > 0">
          <aod-item-goods v-for="(afterGoods, index) in aod.after_goods_list" :key="index" class="aod-goods" :goods="afterGoods" />
        </template>
        <template v-else>
          <aod-item-goods-diff v-for="(afterGoods, index) in aod.after_goods_diff_list" :key="index" class="aod-goods" :goods="afterGoods" />
        </template>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>

<script>
import AodItemGoods from './aod-item-goods'
import AodItemGoodsDiff from './aod-item-goods-diff'
export default {
  components: { AodItemGoods, AodItemGoodsDiff },
  props: {
    aod: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeNames: []
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/color.scss";
  .amount {
    color: $amountRedColor;
  }
  .aod-goods {
    margin-bottom: 10px;
    line-height: normal;
  }
  .aod-goods:last-child {
    margin-bottom: 0;
  }
  .client-name {
    flex: 3;
  }
</style>
