<template>
  <div>
    <my-nav-bar
      title="退货差价"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <div class="list">
        <aod-item v-for="(aod, index) in list" :key="index" class="aod-item" :aod="aod" @show-qrcode="handleShowQrcode" />
      </div>
    </div>
    <my-dialog
      v-model="showQrcodeDialog"
      title="差价收款码"
      :show-cancel-button="false"
    >
      <div class="qrcode">
        <div class="qrcode-label">客户：{{ showAod?.client.name }}</div>
        <div class="qrcode-label">金额：<span class="amount">￥{{ showAod?.amount }}</span></div>
        <van-image :src="qrcodeData" />
      </div>
    </my-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getAodList } from '@/api/delivery_order'
import AodItem from './components/aod-item'
import qrcode from 'qrcode'
import MyDialog from '@/components/my-dialog'
export default {
  components: { myNavBar, AodItem, MyDialog },
  data() {
    return {
      list: [],
      showQrcodeDialog: false,
      qrcodeData: null,
      showAod: null
    }
  },
  created() {
    this.beginLoad()
    getAodList().then(res => {
      this.list = res.data
      this.endLoad()
    })
  },
  methods: {
    handleShowQrcode(aod) {
      this.showAod = aod
      const url = process.env.VUE_APP_PAYMENT_URL + 'after-diff/?after_order_num=' + aod.after_order.num
      qrcode.toDataURL(url, { width: 200 }, (err, data) => {
        if (err) {
          this.fail('生成付款二维码失败')
          console.log(err)
          return
        }
        this.showQrcodeDialog = true
        this.qrcodeData = data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/color.scss';
  .list {
    padding: 10px;
  }
  .aod-item {
    margin-bottom: 10px;
  }
  .aod-item:last-child {
    margin-bottom: 0;
  }
  .qrcode {
    text-align: center;
  }
  .qrcode-label {
    padding-top: 10px;
    font-size: 14px;
    color: #323232;
  }
  .amount {
    color: $amountRedColor;
  }
</style>
