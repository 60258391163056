var render = function () {
  var _vm$showAod, _vm$showAod2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "退货差价",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list, function (aod, index) {
    return _c('aod-item', {
      key: index,
      staticClass: "aod-item",
      attrs: {
        "aod": aod
      },
      on: {
        "show-qrcode": _vm.handleShowQrcode
      }
    });
  }), 1)]), _c('my-dialog', {
    attrs: {
      "title": "差价收款码",
      "show-cancel-button": false
    },
    model: {
      value: _vm.showQrcodeDialog,
      callback: function callback($$v) {
        _vm.showQrcodeDialog = $$v;
      },
      expression: "showQrcodeDialog"
    }
  }, [_c('div', {
    staticClass: "qrcode"
  }, [_c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("客户：" + _vm._s((_vm$showAod = _vm.showAod) === null || _vm$showAod === void 0 ? void 0 : _vm$showAod.client.name))]), _c('div', {
    staticClass: "qrcode-label"
  }, [_vm._v("金额："), _c('span', {
    staticClass: "amount"
  }, [_vm._v("￥" + _vm._s((_vm$showAod2 = _vm.showAod) === null || _vm$showAod2 === void 0 ? void 0 : _vm$showAod2.amount))])]), _c('van-image', {
    attrs: {
      "src": _vm.qrcodeData
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }